<template>
  <AbstractDataTable
    :headers="tableHeaders"
    :items="topics"
    :total-count="totalCount"
    :current-page="currentPage"
    :on-page-change="onPageChange"
    store-name="topic"
    table-css-class="topic-table"
    >
    <template #tableBody="{ item }">
      <TitleColumn
        :title-header="tableHeaders[0].name"
        :title-value="item.id"
        subtitle
      />
      <TitleColumn
        :title-header="tableHeaders[1].name"
        :title-value="item.title"
      />
      <TitleColumn
        :title-header="tableHeaders[2].name"
        :title-value="siteTitleById(item.site)"
      />
      <TitleColumn
        :title-header="tableHeaders[3].name"
        :title-value="categoryTitleByValue(item.category)"
      />
      <TitleColumn
        :title-header="tableHeaders[4].name"
        :title-value="item.slug"
      />
    </template>
  </AbstractDataTable>
</template>

<script>
import AbstractDataTable from '@/components/table/AbstractDataTable'
import TitleColumn from '@/components/table/columns/TitleColumn'
import SiteService from '@/services/site/SiteService'
import TopicCategoryMixin from '@/components/mixins/valueObject/TopicCategoryMixin'

export default {
  name: 'TopicTable',
  mixins: [TopicCategoryMixin],
  props: {
    topics: {
      type: Array,
      required: true
    },
    totalCount: {
      type: [Number, String],
      default: 0
    },
    currentPage: {
      type: Number,
      required: true
    },
    onPageChange: {
      type: Function,
      required: true
    }
  },
  components: {
    TitleColumn,
    AbstractDataTable
  },
  data () {
    return {
      tableHeaders: [
        { name: this.$t('topic.list.id') },
        { name: this.$t('topic.list.title') },
        { name: this.$t('topic.list.site') },
        { name: this.$t('topic.list.category') },
        { name: this.$t('topic.list.slug') },
        { name: this.$t('actions') }
      ]
    }
  },
  methods: {
    siteTitleById (id) {
      return SiteService.getSiteTitle(id)
    },
    categoryTitleByValue (value) {
      const topicCategory = this.topicCategoryValues.find(element => {
        return element.id === value
      })
      if (topicCategory) {
        return topicCategory.title
      }
      return value
    }
  }
}
</script>
<style lang="scss">
  .topic-table {
    &__thead,
    &__tr {
      @include bp(12) {
        grid-template-columns: rem(50px) auto rem(130px) rem(130px) rem(130px) max-content;
      }
      @include bp(14) {
        grid-template-columns: rem(50px) auto rem(160px) rem(160px) rem(160px) rem(138px);
      }
    }
  }
</style>
